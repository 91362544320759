/*  */ import { Component, ViewChild, OnInit } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { BackendService,AuthService,GetPosThresholds,PermissionOSP,RolePermission ,PredicitveDataCount,IThreshold } from '@techops-ui/osp/data-access-root';
import { SortDescriptor } from '@progress/kendo-data-query';
import { process, State } from '@progress/kendo-data-query';
import {  map } from 'rxjs/operators';
import { Router} from '@angular/router';

import { PingAuthenticationService } from '@techops-ui/ping-authentication';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'techops-ui-predictive',
  templateUrl: './predictive.component.html',
  styleUrls: ['./predictive.component.css']
})
export class PredicitveComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;
  applyFilterForm: FormGroup;
  addTheresholdModal = false;
  isLoading = false;
  public filterOpened = false;
  public allowUnsort = false;
  public gridView: GridDataResult;
  public thresholdgridView: GridDataResult;
  thesholdgridData: IThreshold[];
  public thresholdpageSize = 10;
  public thresholdskip = 0;
  fleetData:any = [{fleet:"B737-NG"}] ;
  public thresholdstate: State = {
    skip: 0,
    take: 10,
    sort: [{ dir: 'asc', field: 'cpn' }]
  };
  editData: any;
  public pageSize = 20;
  public skip = 0;
  showLoading: boolean;
  public state: State = {
    skip: 0,
    take: 20,
    sort: []
  };
  public isFormCreate = false;

  gridData:PredicitveDataCount[];
  errordialogeopend = false;
  errorMessage: string;
  public sort: SortDescriptor[] = [
    {
      field: 'noseNumber',
      dir: 'asc'
    }
  ];

  tempFilter:any;
  hideSubGridHeader = {
    display: 'none'
  };
  groupHeaderLeftStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  groupHeaderRightStyle = {
    'background-color': '#6e8999',
    color: '#fff',
    'line-height': '1em',
    //'border-right': '1px solid #5f5353',
    'border-left': '1px solid #5f5353',
    'text-align': 'center'
  };
  addGroupHeaderRightStyle = {
    'background-color': 'rgb(159, 180, 192)',
    color: 'black',
    'line-height': '1em',

    'border-right': '1px solid rgb(182, 165, 165)',
    'text-align': 'center'
  };
  addGroupHeaderLeftStyle = {
    'background-color': 'rgb(159, 180, 192)',
    color: 'black',
    'line-height': '1em',
    'border-right': '1px solid rgb(134, 119, 119)',
    'border-left': '1px solid rgb(182, 165, 165)',
    'text-align': 'center'
  };
  subHeaderBorderBottom = {
    'border-bottom': '1px solid #6e8999'
  };
  headerBorderLeft = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
   headerBorderLeftMain = {
    'background-color': 'rgb(228, 231, 235)',
    'border-left': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
  'text-align':'center',
    'border-bottom-width': '2px'
  };

  headerBorderLeftPredictive = {
    'background-color': 'rgb(228, 231, 235)',
   
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  addHeaderBorderLeft = {
    'background-color': '#ffff',
    'border-left': '1px solid #b29f9f',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  addCurrentHeaderBorderLeft = {
    'background-color': '#ffff',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  headerBorderRight = {
    'background-color': 'rgb(228, 231, 235)',
    'border-right': '1px solid #5f5353',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };

  addHeaderBorderRight = {
    'background-color': '#ffff',
    'border-right': '1px solid #b29f9f',
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  defaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#e4e7eb',
    'border-bottom': '1px solid #5f5353',
    'border-bottom-width': '2px'
  };
  viewDefaultHeader = {
    height: 'auto',
    overflow: 'visible',
    'white-space': 'normal',
    'background-color': '#0000',
    'border-bottom': '1px solid #b29f9f',
    'border-bottom-width': '2px'
  };
  borderLeft = {
    'border-left': '1px solid #5f5353'
  };
  viewBorderLeft = {
    'border-left': '1px solid #b29f9f'
  };
  borderRight = {
    'border-right': '1px solid #5f5353'
  };
  viewBorderRight = {
    'border-right': '1px solid #b29f9f'
  };

  firstCellBorder = {
    'border-top': '1px solid red'
  };
  submitted = false;
  overRideSubmitted = false;
  employee: any;
  roleAccess: PermissionOSP = RolePermission.Default;
  editTheresoldRecordModel = false;
  editTheresoldRecordForm: FormGroup;
  public isFormEdit = false;


  constructor(
    private backendservice: BackendService,
    private authService: PingAuthenticationService,
    private authServices:AuthService,
    private formBuilder: FormBuilder,
    private datePipe:DatePipe,
    private router: Router,
  ) {}

  ngOnInit() {

    this.roleAccess = this.authServices.checkRoleAccess();
   if(!this.roleAccess.predictiveOilAdd){
    this.router.navigate(['/unauthorized']);
   
   }
    this.initLoadReport();
    this.initLoadThreshold();
    this.initAddEditForm();
    this.applyFilterForm = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      
      ac: ['', [Validators.maxLength(3)]],
      station: ['', [Validators.maxLength(30)]],
    
     
    });
    this.authService.profile$.pipe(map(data => data)).subscribe(data => {
      this.employee = data;
     
    });
  }

  public pageChange(event: PageChangeEvent): void {
    
    if (this.state.skip !== event.skip) {
      this.state = { ...this.state, skip: event.skip };
      const filterInput = this.removeEmpty(this.tempFilter);
     if(this.isObjectEmpty(filterInput)){
      this.initLoadReport();
     }else{
      this.applyFilter(false);
     }
     
     
    }
  }
  public thresholddataStateChange(state: DataStateChangeEvent): void {
    this.thresholdstate = state;
    this.thresholdgridView = process(this.thesholdgridData, this.thresholdstate);
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
   
    this.initLoadReport();

  }



  public onAccept() {
    this.submitted = true;
    if (this.editTheresoldRecordForm.invalid) {
      return;
    }
    const formData:any = this.editTheresoldRecordForm.getRawValue();
   
    formData.updatedDate = new Date();
    if (this.isFormEdit) {
      this.backendservice.updatePosThreshold(formData, this.editData.id).subscribe(
        data => {
          if (data) { 
            this.initLoadThreshold();
            this.errordialogeopend = true;
            this.errorMessage = 'Threshold updated successfully';
            this.editTheresoldRecordForm.reset();
            this.editTheresoldRecordModel = false;
            this.submitted = false;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    } else {
      this.backendservice.addPosThreshold(formData).subscribe(
        data => {
          if (data) {
            this.errordialogeopend = true;
            this.errorMessage  = 'Threshold added successfully';
            this.initLoadThreshold(); 
            this.editTheresoldRecordForm.reset();

            this.editTheresoldRecordModel = false;
            this.submitted = false;
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
   
  }
  private initLoadThreshold(): void {
    this.isLoading = true;
    this.backendservice.getGetPosThresholds().subscribe(
      (result: GetPosThresholds[]) => {
        
        this.thesholdgridData = result;
        this.thresholdgridView = {
          data: this.thesholdgridData.slice(this.skip, this.skip + this.pageSize),
          total: this.thesholdgridData.length
        };
        this.isLoading = false;
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  private initAddEditForm() {
    this.editTheresoldRecordForm = this.formBuilder.group({
      updatedBy: ['', [Validators.required]],
      eC_Threshold: ['', Validators.required],
      fleet: ['', Validators.required],
      eE_Threshold: ['', Validators.required],
      eC_OffSet: ['', Validators.required],
      eE_OffSet: ['', Validators.required],
      
    });
   

  }
  editTheresoldRecord(editStatus: boolean,editData:any) {
    this.editTheresoldRecordModel = true;
    
    if (editStatus) {
      this.isFormEdit = true;
      this.editData = editData;
      this.editTheresoldRecordForm.patchValue(editData);
      this.editTheresoldRecordForm.get('updatedBy').disable();
      this.editTheresoldRecordForm.get('fleet').disable();
    } else {
      this.editTheresoldRecordForm.get('updatedBy').enable();
      this.editTheresoldRecordForm.get('fleet').enable();
      this.editTheresoldRecordForm.reset();
      this.isFormEdit = false;

    }
    
    if(this.employee){
      const updatedBy = this.editTheresoldRecordForm.get('updatedBy');
      updatedBy.setValue(Number(this.employee.uid));
     }

   
  }
  closeAircraftRecord() {
    this.editTheresoldRecordModel = false;
   
  }
  closeErrorDialog(){
    this.errordialogeopend = false;
   
  }

 
 resetPagination(){
   this.state  = {
    skip: 0,
    take: 20,
    sort: []
  };
 }
 
  ///inital get reports//
  private initLoadReport(): void {
    this.isLoading = true;
  
    this.backendservice.getPosoutputReport(this.state.skip/this.state.take,this.state.take).subscribe(
      (result: PredicitveDataCount) => {
        
     
        this.gridView = {data:result.posOutout ,total:result.count}
      
        this.isLoading = false;
       
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

filterModalClose(){
  this.filterOpened = false;
  this.applyFilterForm.reset();
  
}

  public resetFilter() {
   // this.filterOpened = false;
    this.applyFilterForm.reset();
   // this.initLoadReport();
  }
  public filterClose() {
     this.filterOpened = false;
     this.applyFilterForm.reset();
     //this.initLoadReport();
   }

   removeEmpty = (obj: any) => {
    if(!obj){
      return obj;
    }
    Object.keys(obj).forEach(k => !obj[k] && obj[k] !== undefined && delete obj[k]);
    return obj;
  };
  isObjectEmpty(objectData:any){
    if(!objectData || objectData == null || objectData == undefined){
      return true;
    }
    return Object.keys(objectData).length === 0;
  }
  applyFilter(fromUI:boolean) {
   
    this.submitted = true;
   
    this.submitted = true;
    const filterValues = this.applyFilterForm.value; 
    filterValues.aircraft =  filterValues.aircraft ?  filterValues.aircraft.toUpperCase():'';
    let filterInput = this.removeEmpty(filterValues);
    if(!fromUI && !this.isObjectEmpty(this.tempFilter)){
      filterInput = this.tempFilter;
    }
    this.isLoading = true;
    this.filterOpened = false;
    if(filterInput.startDate){
      filterInput.startDate = this.datePipe.transform(filterInput.startDate,'yyyy-MM-dd');
    }
    if(filterInput.endDate){
      filterInput.endDate = this.datePipe.transform(filterInput.endDate,'yyyy-MM-dd');
    } 
     if(filterInput.ac){
      filterInput.ac = filterInput.ac.toUpperCase();
    }
    if(filterInput.station){
      filterInput.station = filterInput.station.toUpperCase();
    }
    if(fromUI){
      this.resetPagination();
    }

   

    this.backendservice.filterPosoutputReport(this.state.skip/this.state.take,this.state.take,filterInput).subscribe(
      (result: PredicitveDataCount) => {
        this.tempFilter = filterInput;
        this.gridView = {data:result.posOutout ,total:result.count}
        this.filterOpened = false;
        this.isLoading = false;
        this.applyFilterForm.reset();
        
      },
      (error: any) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  public filterOpen() {
    this.filterOpened = true;
    
  }



  
  

  

  
}
